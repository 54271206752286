<!-- Finder particular de Apds -->

<template>
  <div class="muniF" v-if="loaded">
    
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">       
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor">

          <!-- <div v-html="info"></div> -->

          <div style="display:flex; align-items:center">
            <div class="columna">

              <div style="display:flex">
                <uiText
                  style="width:22rem"
                  v-model="ct.name[2]"
                  :label="ct.name[1]"
                  @Enter="eventFiltro('buscar')"> 
                </uiText>

                <v-btn v-bind="$cfe.btn.busca" @click="eventFiltro('buscar')" style="margin-left:5px">
                  <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
                </v-btn> 
              </div>
              
            </div>
          </div>


          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"           
            dense
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:top_btns="{}">         
                <div style="padding:10px 0 0 10px;">

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>
                  
                </div>
              </template>
            
              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>            
          </baseGridMD>
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto"; 

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto },
    props: {},

    data() {
      return {
        stIni: {
          api: "muniF",
          titulo:"Búsqueda de Municipio", 
          name:"muniF",
          mView:'',
          pView:[]
        },
        
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Municipio", value: "name", witdh: "45%" },
            { text: "Provincia", value: "prov", witdh: "35%" },
            { text: "C.Postal", value: "cp", witdh: "10%" }            
          ]}
        ];

        this.headerGrid= this.headerSet();

      },  


      // gestor de eventos
      /* event_search(nameBtn = "", header) {
        var ctrls= this.schema.ctrls;

        if (ctrls.name.value.length< 3) {
          this.$root.$alert.open('Debe introducir más de 2 caracteres para la búsqueda', 'info');
          return;
        }

        this.buscarExec(nameBtn, header);     
      } */
    }
  };
</script>
